<script setup lang="ts">
import { computed } from 'vue';
import { useCssVar } from '@vueuse/core';

interface Props {
  progress: number;
  size?: number;
  strokeWidth?: number;
  emptyStrokeWidth?: number;
  fillColor?: string;
  emptyColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
  size: 50,
  strokeWidth: 4,
  emptyStrokeWidth: 4,
  fillColor: useCssVar('--color-primary').value,
  emptyColor: `${useCssVar('--color-primary').value}33`,
});

const halfSize = computed(() => props.size / 2);
const radius = computed(() => (props.size - props.strokeWidth) / 2);
const circumference = computed(() => radius.value * Math.PI * 2);
const dash = computed(() => (props.progress * circumference.value) / 100);

// concept taken from https://www.30secondsofcode.org/css/s/circular-progress-bar/
</script>

<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    class="circular-progress"
    :style="`--progress: ${progress}`"
  >
    <circle
      class="bg"
      :cx="halfSize"
      :cy="halfSize"
      :r="radius"
      :stroke-width="emptyStrokeWidth" />
    <circle
      class="fg"
      :cx="halfSize"
      :cy="halfSize"
      :r="radius"
      :stroke-width="strokeWidth" />
  </svg>
</template>

<style>
.circular-progress {
  --progress: v-bind(progress);
  --half-size: v-bind(halfSize);
  --circumference: v-bind(circumference);
  --dash: v-bind(dash);

  circle {
    fill: none;
    stroke-linecap: round;

    &.bg {
      stroke: v-bind(emptyColor);
    }

    &.fg {
      transform: rotate(-90deg);
      transform-origin: var(--half-size) var(--half-size);
      stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
      transition: stroke-dasharray 0.3s linear 0s;
      stroke: v-bind(fillColor);
    }
  }
}
</style>
