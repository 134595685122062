import {
  computed, readonly, ref, unref,
} from 'vue';
import type { MaybeNull, SpecialAccess, User } from '@kcalc/lib/browser';
import { t } from '@/lib/i18n';
import {
  hasActiveSpecialAccess$, hasActiveSubscription$, hasActiveTrial$, isThirdPartyLogin$, specialAccess$, user$,
} from '@/lib/firebase/auth';
import { useAppConfig } from '@/composables/app-config';
import { isThirdParty, platformCode } from '@/lib/platform';

type Fn = (user: User) => void;

const userRef = ref<MaybeNull<User>>(null);

const isLoading = ref<boolean>(true);

const hasActiveTrial = ref<boolean>(true);

const hasActiveSubscription = ref<boolean>(false);

const hasSpecialAccess = ref<boolean>(false);

const specialAccess = ref<MaybeNull<SpecialAccess>>(null);

const isThirdPartyLogin = ref<boolean>(false);

const { fetch } = useAppConfig({ autoFetch: false });

const afterLoginCallbacks: Set<Fn> = new Set<Fn>();

const globalError = ref<MaybeNull<string>>(null);

// Ongoing subscription for user change events
user$.subscribe((user) => {
  console.log('[auth] user changed', user);
  userRef.value = user;

  if (user && afterLoginCallbacks.size > 0) {
    afterLoginCallbacks.forEach((cb) => {
      afterLoginCallbacks.delete(cb);

      cb(user);
    });
  }

  fetch().finally(() => {
    if (isLoading.value) {
      isLoading.value = false;
    }
  });
});

hasActiveTrial$.subscribe((trialState) => {
  hasActiveTrial.value = trialState;
});

hasActiveSubscription$.subscribe((subscriptionState) => {
  hasActiveSubscription.value = subscriptionState;
});

hasActiveSpecialAccess$.subscribe((hasActiveSpecialAccess) => {
  hasSpecialAccess.value = hasActiveSpecialAccess;
});

isThirdPartyLogin$.subscribe((isThirdParty) => {
  isThirdPartyLogin.value = isThirdParty;
});

specialAccess$.subscribe((activeSpecialAccess) => {
  specialAccess.value = activeSpecialAccess;
});

function getOwnerUid(): string {
  return [
    unref(userRef)?.authUser.uid,
    (isThirdParty && !import.meta.env.VITE_EMULATE) ? platformCode : undefined,
  ]
    .filter(Boolean)
    .join('_');
}

const settings = computed(() => userRef.value?.profile?.settings);

const designSettings = computed(() => settings.value?.design);

const locale = computed(() => userRef.value?.profile?.locale ?? 'de');

export function useAuth() {
  const error = ref('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAuthError = (e: any) => {
    error.value = t(`AuthForm.errors.${e?.code ? e.code.split('/')[1] : 'generic'}`);
  };

  const isAuthenticated = computed(() => !isLoading.value && !!userRef.value?.authUser);
  const isVerified = computed(() => !!userRef.value?.authUser?.emailVerified);
  const hasAccess = computed(() => hasActiveTrial.value || hasActiveSubscription.value || hasSpecialAccess.value);
  const hasBusinessAccess = computed(() => {
    if (hasSpecialAccess.value) {
      return specialAccess.value?.plan === 'business';
    }

    if (hasActiveSubscription.value) {
      return userRef.value?.profile?.subscription?.plan === 'business';
    }

    return hasActiveTrial.value;
  });

  return {
    user: userRef,
    isLoading: readonly(isLoading),
    error: computed(() => (globalError.value !== null ? globalError.value : error.value)),
    handleAuthError,
    setGlobalError: (err: string) => {
      globalError.value = err;
    },
    resetGlobalError: () => {
      globalError.value = null;
    },
    isAuthenticated: readonly(isAuthenticated),
    isVerified: readonly(isVerified),
    hasActiveTrial: readonly(hasActiveTrial),
    hasActiveSubscription: readonly(hasActiveSubscription),
    hasAccess: readonly(hasAccess),
    hasSpecialAccess: readonly(hasSpecialAccess),
    specialAccess: readonly(specialAccess),
    isThirdPartyLogin: readonly(isThirdPartyLogin),
    hasBusinessAccess,
    getOwnerUid,
    getUserFoodCollectionName: () => `${getOwnerUid()}_foods`,
    getUserTransactionCollectionName: () => `${getOwnerUid()}_transactions`,
    settings,
    designSettings,
    locale,
    localeBCP47: computed(() => {
      const l = locale.value;
      if (l === 'en') {
        return 'en-US';
      }

      return `${l}-${l.toUpperCase()}`;
    }),
    registerAfterLoginCallback: (fn: Fn) => afterLoginCallbacks.add(fn),
  };
}
