import { createAvatar } from '@dicebear/core';
import { bigEarsNeutral } from '@dicebear/collection';
import { useCssVar } from '@vueuse/core';

interface Options {
  backgroundColor?: string;
  size: number;
}

export function useAvatar(seed: string, options: Options) {
  const systemColor = useCssVar('--color-accent');

  const uri = createAvatar(bigEarsNeutral, {
    seed,
    size: options.size,
    radius: options.size,
    backgroundColor: [(options.backgroundColor || systemColor.value).replace('#', '')],
    mouth: [
      'variant0301',
      'variant0302',
      'variant0303',
      'variant0305',
      'variant0701',
      'variant0702',
      'variant0707',
      'variant0708',
    ],
  }).toDataUriSync();

  return {
    uri,
  };
}
