<template>
  <div
    class="card"
    :class="{ 'card--has-subheader': $slots.subheader }">
    <header v-if="$slots.title || $slots.actions || $slots.secondaryActions">
      <div
        v-if="$slots.title"
        class="header-title">
        <slot name="title" />
      </div>

      <div
        v-if="$slots.actions || $slots.secondaryActions"
        class="header-right">
        <div
          v-if="$slots.actions"
          class="actions">
          <slot name="actions" />
        </div>

        <div
          v-if="$slots.secondaryActions"
          class="secondary-actions">
          <VDropdown
            theme="popover"
            :distance="6"
            placement="bottom"
            popper-class="card-secondary-actions-popover">
            <button class="secondary-actions-trigger">
              <font-awesome-icon icon="ellipsis-v" />
            </button>

            <template #popper="{ hide }">
              <slot
                name="secondaryActions"
                :hide="hide" />
            </template>
          </VDropdown>
        </div>
      </div>
    </header>

    <div
      class="subheader"
      v-if="$slots.subheader">
      <slot name="subheader" />
    </div>

    <div class="card-main">
      <slot />
    </div>

    <footer v-if="$slots.footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<style>
.card {
  --card-background: var(--color-white);
  --card-border-color: var(--color-gray-light);

  background-color: var(--card-background);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;

  &--light-gray {
    --card-background: var(--color-gray-ultra-light);
  }

  header {
    padding: var(--space-3);
    border-bottom: 1px solid var(--card-border-color);
    font-weight: var(--font-weight-bold);
    flex: none;
    display: flex;
    align-items: center;

    .header-title {
      flex: 1 1 auto;
    }

    .header-right {
      margin-left: auto;
      display: flex;
      align-items: center;
      padding-left: var(--space-2);

      .actions {
        display: flex;
        align-items: center;
        padding-left: var(--space-2);

        > * {
          &:not(:first-child) {
            margin-left: var(--space-2);
          }
        }
      }

      .secondary-actions {
        padding-left: var(--space-2);

        .secondary-actions-trigger {
          padding: 0 var(--space-2);
          margin-right: calc(-1 * var(--space-2));
          border: 0;
          background: none;
          -webkit-appearance: none;
          cursor: pointer;
          transition: all var(--transition-duration-default) var(--transition-animation-default);

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  &--has-subheader {
    header {
      border-bottom: none;
      padding-bottom: 0;
    }

    .subheader {
      padding: var(--space-2) var(--space-3) var(--space-1);
      border-bottom: 1px solid var(--card-border-color);
      font-size: var(--font-size-xs);
      font-weight: var(--font-weight-bold);
      display: flex;
    }
  }

  .card-main {
    padding: var(--space-3);
    flex: 1 1 auto;
  }

  footer {
    padding: var(--space-3);
    border-top: 1px solid var(--card-border-color);
    flex: none;
  }
}
</style>
