import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircle,
  faCircleCheck,
  faCreditCard,
  faEye,
  faEyeSlash,
  faFilePdf,
  faFloppyDisk,
  faImage,
  faStar as faStarOutline,
  faThumbsUp as faThumbsUpOutline,
  faTrashCan,
  faXmarkCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowUpRightDots,
  faBuildingColumns,
  faCakeCandles,
  faCartShopping,
  faCertificate,
  faChartLine,
  faChartPie,
  faCheck,
  faCircleCheck as faCircleCheckSolid,
  faCircleExclamation,
  faCircleMinus,
  faCircleNotch,
  faCirclePlus,
  faCircleQuestion,
  faCopy,
  faDownload,
  faEllipsisV,
  faExclamationCircle,
  faFileInvoiceDollar,
  faFire,
  faGear,
  faGears,
  faGem,
  faGripVertical,
  faInfo,
  faList,
  faListCheck,
  faLockOpen,
  faMagnifyingGlass,
  faMinus,
  faNewspaper,
  faPalette,
  faPenToSquare,
  faPersonBiking,
  faPlus,
  faRocket,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTableList,
  faThumbsUp,
  faTimeline,
  faTriangleExclamation,
  faUser,
  faUsers,
  faUserSlash,
  faUtensils,
  faWeightHanging,
  faWeightScale,
  faNoteSticky,
  faCloud,
  faMobileScreen,
  faShareFromSquare,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faEye,
  faEyeSlash,
  faXmarkCircle,
  faCircleNotch,
  faSort,
  faSortUp,
  faSortDown,
  faGear,
  faStar,
  faStarOutline,
  faPenToSquare,
  faCirclePlus,
  faPlus,
  faMinus,
  faCircleMinus,
  faArrowRightFromBracket,
  faArrowRight,
  faLockOpen,
  faUserSlash,
  faCreditCard,
  faFloppyDisk,
  faTrashCan,
  faCartShopping,
  faCircleExclamation,
  faCircleCheck,
  faTriangleExclamation,
  faInfo,
  faMagnifyingGlass,
  faEllipsisV,
  faCircle,
  faCircleCheckSolid,
  faAngleLeft,
  faChartLine,
  faFilePdf,
  faChartPie,
  faListCheck,
  faList,
  faCopy,
  faWeightScale,
  faCakeCandles,
  faWeightHanging,
  faFire,
  faExclamationCircle,
  faUtensils,
  faTableList,
  faTimeline,
  faNewspaper,
  faDownload,
  faBuildingColumns,
  faGears,
  faPalette,
  faFileInvoiceDollar,
  faPersonBiking,
  faCircleQuestion,
  faImage,
  faRocket,
  faGripVertical,
  faCheck,
  faRocket,
  faUser,
  faUsers,
  faThumbsUpOutline,
  faThumbsUp,
  faArrowUpRightDots,
  faGem,
  faCertificate,
  faNoteSticky,
  faCloud,
  faMobileScreen,
  faShareFromSquare,
);
