export const roundNumber = (value: number, decimals = 2) => {
  const power = 10 ** decimals;

  return Math.round((value + Number.EPSILON) * power) / power;
};

export const roundAndFormatNumber = (value: number, decimals = 2) => {
  const val = roundNumber(value, decimals).toString();

  return decimals > 0
    ? val.replace('.', ',')
    : val;
};

/**
 * Thanks, ChatGPT
 */
export function generateRandomString(length: number, config?: { includeSpecialChars: boolean }) {
  const {
    includeSpecialChars = true,
  } = config || {};

  const chars = [
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    includeSpecialChars ? '!@#$%^&*()_-+[]{}|;:,.<>?' : '',
  ].join('');

  const randomStrParts = [];
  const randomBuffer = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < randomBuffer.length; i++) {
    randomBuffer[i] = Math.floor(Math.random() * 256);
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    randomStrParts.push(chars[randomBuffer[i] % chars.length]);
  }

  return randomStrParts.join('');
}
