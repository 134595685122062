import 'simplebar';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import { createApp } from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import FloatingVue from 'floating-vue';
import Toast, { POSITION } from 'vue-toastification';

import type { FloatingVueConfig } from 'floating-vue/dist/config';
import { Chart } from 'chart.js';
import VueGtag from 'vue-gtag';
import App from '@/App.vue';
import router from '@/router';
import { i18n } from '@/lib/i18n';
import '@/lib/icons';
import '@/lib/analytics';

import 'floating-vue/dist/style.css';
import '@/assets/styles/index.css';
import '@vueform/multiselect/themes/default.css';
import 'instantsearch.css/themes/reset-min.css';
import { is44zero, platformCode } from '@/lib/platform';
import { head } from '@/lib/head';

if (!is44zero) {
  import(`@/assets/styles/white-label/${platformCode}.css`);
}

const app = createApp(App);

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', /^\//],
      }),
    ],
    tracesSampleRate: import.meta.env.MODE === 'production' ? 0.75 : 1.0,
    logErrors: import.meta.env.DEV,
    enabled: import.meta.env.PROD,
    environment: import.meta.env.MODE,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

// see https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
  window.location.reload();
});

app.use(router);
app.use(i18n);
app.use(FloatingVue, {
  themes: {
    popover: {
      $extend: 'dropdown',
      $resetCss: true,
      distance: -4,
    },
    'secondary-tooltip': {
      $extend: 'tooltip',
    },
    tooltip: {
      autoHide: true,
    },
  },
  disposeTimeout: 0,
} as FloatingVueConfig);
app.use(Toast, {
  position: POSITION.TOP_RIGHT,
});

app.use(head);

if (import.meta.env.VITE_GOOGLE_ANALYTICS_ID) {
  app.use(VueGtag, {
    enabled: false,
    bootstrap: false,
    // TODO seems not to be a valid option
    // pageTrackerUseFullPath: true,
    config: {
      id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
    },
  }, router);
}

// eslint-disable-next-line vue/component-definition-name-casing
app.component('font-awesome-icon', FontAwesomeIcon);
// eslint-disable-next-line vue/component-definition-name-casing
app.component('font-awesome-layers', FontAwesomeLayers);

document.documentElement.classList.add(`platform--${platformCode}`);

Chart.defaults.font.family = "'Sora', sans-serif";

app.mount('#app');
