import type {
  CustomerProfile,
  FreeTimePalFactorKey,
  JobPalFactorKey,
  NeedsAnalysis,
  NeedsAnalysisObject,
  User,
} from '@kcalc/lib/browser';
import {
  freeTimePalFactorsKeys,
  jobPalFactorsKeys,
  roundNumber,
  useNeedsAnalysis,
  ageByDateOfBirth,
} from '@kcalc/lib/browser';
import type { PartialDeep } from 'type-fest';
import { getDoc } from 'firebase/firestore';
import { t } from '@/lib/i18n';
import { customer as customerDoc, updateNeedsAnalysis } from '@/lib/firebase/store';
import { useAppConfig } from '@/composables/app-config';

export const jobPALFactorOptions = (user: User) => (
  Object.fromEntries(jobPalFactorsKeys.map((key) => {
    const palFactor = user.profile?.settings?.palFactors?.job[key] ? user.profile.settings.palFactors.job[key].toLocaleString() : 0;
    return [
      key,
      `${t('NeedsAnalysis.physicalLoad.palFactors.grade')} ${key} (${palFactor}): ${t(`NeedsAnalysis.physicalLoad.palFactors.job.${key}`)}`,
    ];
  })) as { [key in JobPalFactorKey]: string; }
);

export const freeTimePALFactorOptions = (user: User) => (
  Object.fromEntries(freeTimePalFactorsKeys.map((key) => {
    const palFactor = user.profile?.settings?.palFactors?.freeTime[key] ? user.profile.settings.palFactors.freeTime[key].toLocaleString() : 0;
    return [
      key,
      `${t('NeedsAnalysis.physicalLoad.palFactors.grade')} ${key} (${palFactor}): ${t(`NeedsAnalysis.physicalLoad.palFactors.freeTime.${key}`)}`,
    ];
  })) as { [key in FreeTimePalFactorKey]: string; }
);

const { getConfig } = useAppConfig();

export const enrichNeedsAnalysis = async (data: NeedsAnalysis, user: User, customer?: CustomerProfile): Promise<NeedsAnalysisObject> => {
  const customerProfile = customer ?? await getDoc(customerDoc(user.authUser.uid, data.customerId)).then((d) => d.data());

  const lib = useNeedsAnalysis({
    needsAnalysis: data,
    config: {
      sportTypes: getConfig('sportTypes'),
      palFactors: user.profile?.settings?.palFactors,
    },
    customer: {
      dateOfBirth: customerProfile?.personalData?.dateOfBirth,
      gender: customerProfile?.personalData?.gender,
    },
  });

  const customerName = () => (
    customerProfile?.personalData?.firstName
    && customerProfile?.personalData?.surname
      ? `${customerProfile.personalData.firstName} ${customerProfile.personalData.surname}`
      : undefined
  );

  return {
    ...data,
    customer: customerProfile,
    customerName,
    update: async (update: PartialDeep<NeedsAnalysis>) => {
      await updateNeedsAnalysis(user.authUser.uid, data.id, update);
    },
    fatFreeMass: () => roundNumber(lib.fatFreeMass()),
    thq: () => roundNumber(lib.thq()),
    bmi: () => roundNumber(lib.bmi()),
    bmiLevel: lib.bmiLevel,
    weeksUntilGoalDeadline: lib.weeksUntilGoalDeadline,
    daysUntilGoalDeadline: lib.daysUntilGoalDeadline,
    bmr: () => roundNumber(lib.bmr(), 0),
    pregnancyDemand: lib.pregnancyDemand,
    breastfeedingDemand: lib.breastfeedingDemand,
    bmrTotal: () => roundNumber(lib.bmrTotal(), 0),
    individualPalValue: () => roundNumber(lib.individualPalValue(), 2),
    bmrTotalWithPal: () => roundNumber(lib.bmrTotalWithPal(), 0),
    sportsDemand: () => roundNumber(lib.sportsDemand(), 0),
    energyDemandSubtotal: () => roundNumber(lib.energyDemandSubtotal(), 0),
    goalDemand: () => roundNumber(lib.goalDemand(), 0),
    energyDemandTotal: () => roundNumber(lib.energyDemandTotal(), 0),
    energyAvailabilityThreshold: () => roundNumber(lib.energyAvailabilityThreshold(), 2),
    percentageCompleted: () => (
      roundNumber(((data.completedSections ?? 0) / 8) * 100, 0)
    ),
    jobPALFactorOptions: () => jobPALFactorOptions(user),
    freeTimePALFactorOptions: () => freeTimePALFactorOptions(user),
  };
};

export {
  ageByDateOfBirth,
};
