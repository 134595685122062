<script setup lang="ts">
import { lastValueFrom } from 'rxjs';
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { usePayment } from '@/composables/firebase/payment';
import BaseButton from '@/components/BaseButton.vue';
import { startChangePaymentMethodFlow$ } from '@/lib/firebase/functions';
import { useSystemMessage } from '@/composables/system-message';

const { subscription: activeSubscription, previousSubscription } = usePayment();
const { setSystemMessageByCode } = useSystemMessage();

const subscription = computed(() => activeSubscription.value || previousSubscription.value);

const paymentMethodChangeRunning = ref(false);
const changePaymentMethod = async () => {
  paymentMethodChangeRunning.value = true;
  await lastValueFrom(startChangePaymentMethodFlow$()).then((response) => {
    location.href = response;
    paymentMethodChangeRunning.value = false;
  }).catch((e) => {
    paymentMethodChangeRunning.value = false;
    setSystemMessageByCode(e.message);
  });
};

const gracePeriodEnd = computed(() => (
  subscription.value?.suspendedAt
    ? DateTime.fromISO(subscription.value.suspendedAt).plus({ days: 7 }).toFormat('dd.MM.yyyy, HH:mm')
    : null
));
</script>

<template>
  <div v-if="subscription && subscription.status === 'suspended'">
    <p
      v-if="activeSubscription"
      v-html="$t('SuspendedSubscription.description.gracePeriod', { gracePeriodEnd })" />
    <p
      v-else
      v-html="$t('SuspendedSubscription.description.afterGracePeriod', { gracePeriodEnd })" />
    <p class="mt-3">
      <BaseButton
        class="btn-primary"
        @click="changePaymentMethod"
        :loading="paymentMethodChangeRunning"
        :icon="['far', 'credit-card']">
        {{ $t('SuspendedSubscription.cta') }}
      </BaseButton>
    </p>
  </div>
</template>
