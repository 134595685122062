import { DateTime } from 'luxon';
import type {
  BmrFormula, BreastfeedingOption, Gender, NeedsAnalysis, UserProfileSettings,
} from './typings';
import { roundNumber } from './util';
import { getT, type SupportedLanguage } from './i18n';

export interface CalcBmrArgs {
  bodyWeight?: number;
  fatFreeMass?: number;
  bodySize?: number;
  bodyFat?: number;
  dateOfBirth?: string;
  gender?: Gender,
}

interface UseNeedsAnalysisPayload {
  needsAnalysis: NeedsAnalysis;
  customer: {
    dateOfBirth?: string;
    gender?: Gender;
  };
  config: {
    palFactors?: UserProfileSettings['palFactors'];
    sportTypes?: {
      id: number;
      met: number;
    }[];
    lng?: SupportedLanguage
  };
}

export function calcFatFreeMass(bodyWeight: number, bodyFat: number): number {
  return bodyWeight - ((bodyWeight / 100) * bodyFat);
}

export function calcTHQ(hipCircumference: number, waistCircumference: number): number {
  return waistCircumference / hipCircumference;
}

export function calcBMI(bodyWeight: number, bodySize: number): number {
  return bodyWeight / ((bodySize / 100) ** 2);
}

export function ageByDateOfBirth(dateOfBirth: string): number {
  return Math.floor(DateTime.now().diff(DateTime.fromFormat(dateOfBirth, 'yyyy-MM-dd'), 'years').years);
}

const bmrFormulas = {
  harris_benedict: (bodyWeight: number, bodySize: number, age: number, gender: Gender): number => {
    if (gender === 'male') {
      return 66.473 + (13.752 * bodyWeight) + (5.003 * bodySize) - (6.755 * age);
    }

    return 655.096 + (9.563 * bodyWeight) + (1.85 * bodySize) - (4.676 * age);
  },
  dge: (bodyWeight: number, age: number, gender: Gender): number => {
    if (gender === 'male') {
      return ((0.047 * bodyWeight) + 1.009 - (0.01452 * age) + 3.21) * 239;
    }

    return ((0.047 * bodyWeight) - (0.01452 * age) + 3.21) * 239;
  },
  bmi: (bodyWeight: number, bodySize: number, age: number, gender: Gender, fatFreeMass?: number): number => {
    const _bmi = calcBMI(bodyWeight, bodySize);
    const weight = fatFreeMass ?? bodyWeight;

    if (_bmi > 30) {
      return ((0.05 * weight) + (1.103 * (gender === 'male' ? 1 : 0)) - (0.016 * age) + 2.924) * 239;
    }

    if (_bmi >= 25) {
      return ((0.045 * weight) + (1.006 * (gender === 'male' ? 1 : 0)) - (0.015 * age) + 3.407) * 239;
    }

    // formula is not applicable for BMI < 25
    return 0;
  },
  cunningham: (fatFreeMass: number): number => roundNumber(500 + (22 * fatFreeMass), 0),
  de_lorenzo: (bodyWeight: number, bodySize: number): number => roundNumber(-857 + (9 * bodyWeight) + (11.7 * bodySize), 0),
} as const;

export function calcBmr(formula: BmrFormula, args: CalcBmrArgs): number {
  if (
    args.bodyWeight
    && args.bodySize
    && args.dateOfBirth
    && args.gender
  ) {
    const age = ageByDateOfBirth(args.dateOfBirth);
    const {
      bodyWeight, fatFreeMass: _fatFreeMass, bodySize, gender,
    } = args;

    switch (formula) {
      case 'dge':
        return bmrFormulas.dge(_fatFreeMass ?? bodyWeight, age, gender);
      case 'bmi':
        return bmrFormulas.bmi(bodyWeight, bodySize, age, gender, _fatFreeMass);
      case 'de_lorenzo':
        return bmrFormulas.de_lorenzo(_fatFreeMass ?? bodyWeight, bodySize);
      case 'cunningham':
        return _fatFreeMass ? bmrFormulas.cunningham(_fatFreeMass) : 0;
      default:
        return bmrFormulas.harris_benedict(_fatFreeMass ?? bodyWeight, bodySize, age, gender);
    }
  }

  return 0;
}

export function getDemandForPregnancy(): number {
  return 255;
}

export function getBMILevel(bmi: number, lng: SupportedLanguage = 'de'): string {
  const t = getT(lng);

  if (bmi < 18.5) {
    return t('lib:bmi.levels.underweight');
  }

  if (bmi < 24.9) {
    return t('lib:bmi.levels.normalWeight');
  }

  if (bmi < 29.9) {
    return t('lib:bmi.levels.overweight1');
  }

  if (bmi < 34.9) {
    return t('lib:bmi.levels.overweight2');
  }

  if (bmi < 39.9) {
    return t('lib:bmi.levels.overweight3');
  }

  return t('lib:bmi.levels.overweight4');
}

export function getDemandForBreastfeeding(breastfeeding: BreastfeedingOption): number {
  switch (breastfeeding) {
    case 'up_to_4_month':
      return 635;
    case 'after_4_month_full':
      return 525;
    case 'after_4_month_partial':
      return 285;
    default:
      return 0;
  }
}

export function useNeedsAnalysis(payload: UseNeedsAnalysisPayload) {
  const { needsAnalysis: data, customer, config } = payload;

  function fatFreeMass(): number {
    if (data.bodyWeight && data.bodyFat) {
      return data.bodyWeight - ((data.bodyWeight / 100) * data.bodyFat);
    }

    return 0;
  }

  function thq(): number {
    if (data.hipCircumference && data.waistCircumference) {
      return data.waistCircumference / data.hipCircumference;
    }

    return 0;
  }

  function bmi(): number {
    if (data.bodyWeight && data.bodySize) {
      return data.bodyWeight / ((data.bodySize / 100) ** 2);
    }

    return 0;
  }

  function bmiLevel(): string | undefined {
    const _bmi = bmi();

    return _bmi ? getBMILevel(_bmi, config.lng) : undefined;
  }

  function weeksUntilGoalDeadline(): number {
    return data.goal?.deadline
      ? roundNumber(DateTime.fromFormat(data.goal.deadline, 'yyyy-MM-dd').diffNow('weeks').weeks, 0)
      : 0;
  }

  function daysUntilGoalDeadline(): number {
    return data.goal?.deadline
      ? roundNumber(DateTime.fromFormat(data.goal.deadline, 'yyyy-MM-dd').diffNow('days').days, 0)
      : 0;
  }

  const bmr = (formula?: BmrFormula, bmrPayload?: CalcBmrArgs): number => calcBmr(formula ?? data.bmrFormula ?? 'harris_benedict', bmrPayload ?? {
    bodyWeight: data.bodyWeight,
    fatFreeMass: data.considerFatFreeMass && data.bodyFat
      ? fatFreeMass()
      : undefined,
    bodySize: data.bodySize,
    bodyFat: data.bodyFat,
    dateOfBirth: customer.dateOfBirth,
    gender: customer.gender,
  });

  function pregnancyDemand(): number {
    return data.pregnant ? getDemandForPregnancy() : 0;
  }

  function breastfeedingDemand(): number {
    return data.breastfeeding ? getDemandForBreastfeeding(data.breastfeeding) : 0;
  }

  function bmrTotal(): number {
    return bmr() + pregnancyDemand() + breastfeedingDemand();
  }

  function individualPalValue(): number {
    if (
      data.physicalLoad?.job
      && data.physicalLoad?.freeTime
      && config.palFactors
      && data.dailySchedule
    ) {
      const palJob = config.palFactors.job[data.physicalLoad.job];
      const palFreeTime = config.palFactors.freeTime[data.physicalLoad.freeTime];
      const palSleep = 0.95;

      const { work, freeTime, sleep } = data.dailySchedule;

      return ((palJob * work) + (palFreeTime * freeTime) + (palSleep * sleep)) / 24;
    }

    return 0;
  }

  function bmrTotalWithPal(): number {
    return individualPalValue() * bmrTotal();
  }

  function sportsDemand(): number {
    if (data.sports && data.bodyWeight && config.sportTypes) {
      let demand = 0;

      data.sports.forEach((sport) => {
        const met = config.sportTypes!.find((row) => row.id === parseInt(sport.id))?.met;

        if (met) {
          demand += sport.amount * met * (data.bodyWeight as number);
        }
      });

      return demand / 7;
    }

    return 0;
  }

  function energyDemandSubtotal(): number {
    return bmrTotalWithPal() + sportsDemand();
  }

  function goalDemand(): number {
    const days = daysUntilGoalDeadline();
    if (
      data.goal?.type
      && ['gain_weight', 'loose_weight'].includes(data.goal.type)
      && typeof data.goal.amount === 'number'
      && days
    ) {
      if (data.goal.type === 'loose_weight') {
        return (-1 * data.goal.amount * 7000) / days;
      }

      return (data.goal.amount * 7000) / days;
    }

    return 0;
  }

  function energyDemandTotal(): number {
    return energyDemandSubtotal() + goalDemand() + (data.manualEnergyAdjustment ?? 0);
  }

  function energyAvailabilityThreshold(): number {
    const ffm = fatFreeMass();

    if (ffm) {
      return ffm * 30;
    }

    return 0;
  }

  return {
    fatFreeMass,
    thq,
    bmi,
    bmiLevel,
    weeksUntilGoalDeadline,
    daysUntilGoalDeadline,
    bmr,
    pregnancyDemand,
    breastfeedingDemand,
    bmrTotal,
    individualPalValue,
    bmrTotalWithPal,
    sportsDemand,
    energyDemandSubtotal,
    goalDemand,
    energyDemandTotal,
    energyAvailabilityThreshold,
  };
}
